<template>
  <div id="userlist">
    <section class="table-search">
      <div class="search-L">
        <el-input v-model="tableParams.kw" placeholder="请输入手机号搜索" @input="kwChange" class="mr15" />
        <el-select v-model="tableParams.roleIds" placeholder="请选择角色" @change="reload" clearable>
          <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增用户</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="姓名" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.userName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="手机" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.mobile || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="性别" align="center">
        <template slot-scope="scope">
          <span>{{ sexObj[scope.row.sex] || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="角色" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.roleName || "-" }}</span>
        </template>
      </el-table-column>
     
      <!-- <el-table-column label="类型" align="center">
        <template slot-scope="scope">
          <span :style="{ 'color': isAdminColor[scope.row.isAdmin] }">{{ isAdminObj[scope.row.isAdmin] || "-" }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="是否系统管理员" align="center">
        <template slot-scope="scope">
          <span>
           {{ scope.row.isAdmin == 1 ? '是' : '否' }}
          </span> </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)"
            v-has="'setting_user_edit'">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)" v-has="'setting_user_del'"
            v-if="!scope.row.isAdmin">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="editShow" :title="editTitle" :close-on-click-modal="false" width="40%"
      custom-class="add-dialog">
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 姓名 -->
        <el-form-item label="姓名" label-width="120px" prop="userName">
          <el-input v-model.trim="params.userName" autocomplete="off" maxlength="16" placeholder="请填写姓名"></el-input>
        </el-form-item>
        <!-- 性别 -->
        <el-form-item label="性别" label-width="120px" prop="sex">
          <el-select v-model="params.sex" placeholder="请选择性别">
            <el-option v-for="item in ObjToOpt(sexObj)" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 联系方式 -->
        <el-form-item label="手机" label-width="120px" prop="mobile">
          <el-input type="number" v-model="params.mobile" autocomplete="off" placeholder="请填写11位手机号"></el-input>
        </el-form-item>
        <!-- 角色 -->
        <el-form-item label="角色" label-width="120px" prop="roleIds">
          <el-select v-model="params.roleIds" placeholder="请选择角色" multiple collapse-tags class="tags-del-none">
            <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"
              :disabled="!item.enableEdit">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 是否管理员 -->
        <!-- <el-form-item label="类型" label-width="120px" prop="isAdmin">
          <el-select v-model="params.isAdmin" placeholder="请选择类型">
            <el-option v-for="item in ObjToOpt(isAdminObj)" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="是否系统管理员" label-width="120px" prop="isAdmin">
          <el-radio-group v-model="params.isAdmin">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserList,
  getUserEdit,
  getUserDel
} from "@/api/setting/user";
import {
  getRoleList,
} from "@/api/setting/role"
import {
  sexObj,
  isAdminObj,
  isAdminColor
} from "@/db/objs"
import {
  ObjToOpt,
} from "@/utils/utils"
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      rules,
      sexObj,
      ObjToOpt,
      isAdminObj,
      isAdminColor,
      // table列表
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],
      tableParams: {
        page: 1,
        size: 10,
        kw: "",
        roleIds: "",
      },
      // 表单
      editShow: false, //弹框开关
      editTitle: "", //弹框姓名
      roleList: [],//角色列表
      params: {
        userId: "", //用户ID
        userName: "", //姓名
        mobile: "", //联系方式
        roleIds: "", //角色
        isAdmin: 0, //类型
        sex: 0, //性别
      },

    };
  },
  mounted() {
    this.getUserList();
    this.getRoleList();
  },
  methods: {
    // 【请求】角色列表
    getRoleList() {
      getRoleList({}).then((res) => {
        this.roleList = res.data.list;
      });
    },

    // 【请求】用户列表
    getUserList() {
      let data = this.tableParams;
      getUserList(data).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },

    // 【请求】新增用户
    getUserEdit() {
      let data = this.params;
      data.roleIds = data.roleIds.toString();
      getUserEdit(data).then(res => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          if (data.userId) {
            this.getUserList();
          } else {
            this.reload()
          }
        }
      })
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getUserList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getUserList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】新增用户
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增用户";
      this.params = {
        userId: "", //用户ID
        userName: "", //姓名
        mobile: "", //联系方式
        roleIds: "", //角色
        isAdmin: 0, //类型
        sex: 0, //性别
      }
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑按钮
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑用户";
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getUserEdit();
        } else {
          return false;
        }
      });
    },

    // 【监听删除】
    onDel(row) {
      let data = {
        userId: row.userId
      }
      this.$confirm('你确定要删除该用户吗?', '删除用户', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getUserDel(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getUserList();
          }
        })
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#userlist {
  padding: .15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}
/deep/.el-radio-group{
  display: inline-block !important;
}
</style>
